import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";


function FormComponent() {
  const { register, handleSubmit, reset} = useForm();
  const postData = async (data) => {

    const Toast = Swal.mixin({
      position: 'center',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    try {
      let res = await axios.post('http://54.172.185.212:8000/subscription', data, {
        headers: {
          token: '82cdc8805bcfdde70c649841fd3afd35c271f91d8e58a9ee7f1362c60bc16cef',
        },
        responseType: 'blob'
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Usted se encuentra participando!'
      })

      setTimeout( () => {
        window.location = URL.createObjectURL(res.data);
      },1500)
    
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Usted ya se encuentra participando!'
      })
    }
  }

  const onSubmit = data => postData(data);


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Nombre</Form.Label>
        <Form.Control type="text" placeholder="Ingrese Nombre" {...register("name")} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicLastName">
        <Form.Label>Apellido</Form.Label>
        <Form.Control type="text" placeholder="Ingrese Apellido" {...register("last_name")} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCedula">
        <Form.Label>Cédula</Form.Label>
        <Form.Control type="number" placeholder="12547896" {...register("cedula")} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Teléfono</Form.Label>
        <Form.Control type="number" placeholder="04127845624" {...register("phone")} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Correo Electrónico</Form.Label>
        <Form.Control type="email" placeholder="posglobalca@gmail.com" {...register("mail")} />
      </Form.Group>
      <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      <div className="d-grid pt-3" class="g-recaptcha" data-sitekey="6LdF9BgjAAAAAG5P2lNTNoZ49KU8BL-Yvr6hdvM3">
        <br/>
        <Button variant="primary" type="submit" size="lg">
          Imprimir Ticket
        </Button>
      </div>
    </Form>
  );
}

export default FormComponent;
