import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';

function Winner() {

  const [band, setBand] = useState(false);
  const [finish, setFinish] = useState( true);

  const getWinner = async() => {
    console.log('asdas');
    try {
      let res = await axios.get('http://54.172.185.212:8000/subscription/winner')


      const Toast = Swal.mixin({
        title: `<p>El Ganador es: ${res.data.name} ${res.data.last_name} <br/> CI: ${res.data.cedula} <br/> Serial: ${res.data._id.slice(res.data._id.length -8,res.data._id.length)}</p>`,
        width: 600,
        height: 800,
        imageHeight: 500,
        showConfirmButton: false,
        padding: '3em',
        color: '#0d6efd',
        background: '#fff url(/images/trees.png)',
        backdrop: `
        rgba(13, 110, 253, .8)


          url("https://img1.picmix.com/output/stamp/normal/1/4/3/4/534341_334df.gif")
          left top
        
        `
      })

      setTimeout( () => {
        setFinish(false)
        Toast.fire()
      },2500)

      
    } catch (error) {
      
    }
  }

  return (
  <>
    <div className="d-flex justify-content-center">
      <img src = {require('./assets/posglobal.png')} style={{paddingBottom: '5%' ,height: '50%', width: '50%'}}/>
    </div>
    <div className='text-center' style={{paddingRight: '20%',paddingLeft: '20%'}}>
    
      {
        band
        ? <Spinner className={`${finish? "": 'd-none'}`}  animation="border" variant="primary" size='xxl'  style={{height: '10rem',width: '10rem',marginRight: '25px'}}/>
        : <div className="d-grid pt-3" >
            <Button variant="primary" type="button" size="lg" onClick={() => {setBand(true);getWinner()}}>
              Empezar Sorteo!
            </Button>
          </div>
      }
    </div>

    <div class="col-md-12" >
      <div class="footer-manu">
        <div class="d-flex justify-content-center">
          <img src = {require('./assets/sunmi.png')} style={{paddingTop: '20px', height: '10%', width: '10%'}}/>
        </div>
        <p class= "text-center" >Powered by <b>Sunmi Corporation, C.A.</b></p>
      </div>
    </div>
  </>
  );
}

export default Winner;