import 'bootstrap/dist/css/bootstrap.min.css';
import FormComponent from './Form';

function App() {
  return (
  <>
    <div class="d-flex justify-content-center">
      <img src = {require('./assets/posglobal.png')} style={{paddingBottom: '5%' ,height: '50%', width: '50%'}}/>
    </div>
    <div className='' style={{paddingRight: '20%',paddingLeft: '20%'}}>
      <FormComponent />
    </div>

    <div class="col-md-12" >
      <div class="footer-manu">
        <div class="d-flex justify-content-center">
          <img src = {require('./assets/sunmi.png')} style={{paddingTop: '20px', height: '10%', width: '10%'}}/>
        </div>
        <p class= "text-center" >Powered by <b>Sunmi Corporation, C.A.</b></p>
      </div>
    </div>
  </>
  );
}

export default App;
